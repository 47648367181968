import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Chatbot"
              description="ChatBot is a web application built with React, Next.js, TypeScript, Tailwind CSS, and deployed on Vercel. It now features user registration and login, enabling personalized access to services. A sensitive word filter has been added to maintain a respectful environment. While not currently active, a user payment functionality is also in place for future needs. For accurate details, check the source code or contact the maintainers."
              ghLink="https://github.com/GarrickGG/ChatbotSourceCode"
              demoLink="https://chat-gpt-next-web-rsv2.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Social Media Application"
              description="The MERN-Social-Media project is a comprehensive social media application built on the MERN stack, which includes MongoDB, Express.js, React.js, and Node.js. It provides a range of social media functionalities such as user registration, login, post creation, commenting, liking, and profile management."
              ghLink="https://github.com/GarrickGG/Social_Media_APP"
              demoLink="https://social-media-app-seven-black.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Finance Application"
              description="The Finance-App is a user-friendly financial application. It's designed to provide an intuitive interface for users to easily track and manage their financial status. with React, Redux, Node.js, Express.js..."
              ghLink="https://github.com/GarrickGG/FinanceApp"
              demoLink="https://finance-dashboard-app-rose.vercel.app/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="E-Commerce Platform"
              description="Using React, Material UI, Stripe, Formik, Yup, Strapi, and Redux Toolkit to build this entire application. This application will allow you to add products into a cart, have a form to fill out information and make payments through Stripe with a backend supported by Strapi."
              ghLink="https://github.com/GarrickGG/E-Commerce"
              //demoLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
